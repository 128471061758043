import React from 'react';
import { H3 } from '@increasecard/typed-components';
import styled, { css } from 'styled-components';
import GatsbyImage from 'gatsby-image';

import { Container as BaseContainer } from '../components/Container';
import { MEDIUM } from '../breakpoints';
import { useStaticQuery, graphql } from 'gatsby';
import { CountriesAllProductsBackground } from '../backgrounds/CountriesAllProductsBackground';
import { SuperHeader } from '../components/Typography';
import {
  CountriesTable,
  CountriesTableWrapper,
  CountryAvailabilityCircle,
} from '../components/CountriesTable/CountriesTable';

const productOrder = {
  card: 1,
  match: 2,
  pay: 3,
  count: 4,
};

const Container = styled(BaseContainer)`
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;

  ${({ variant, theme }) =>
    variant === 'increase' &&
    css`
      color: ${theme.colorsNew.gray.black};
    `}
`;

const Content = styled.div`
  position: relative;
`;

const ImageWrapper = styled.div`
  display: none;
  width: 372px;
  position: absolute;
  top: 120px;
  right: -40px;
  z-index: 0;
  @media (min-width: ${MEDIUM}) {
    display: block;
  }
`;

const OnlyDesktop = styled.div`
  display: none;

  @media (min-width: ${MEDIUM}) {
    display: block;
  }
`;

const OnlyMobile = styled.div`
  display: block;

  @media (min-width: ${MEDIUM}) {
    display: none;
  }
`;

export function CountriesAllProductsSection({
  title,
  description,
  image,
  variant,
}) {
  const { countries, products } = useStaticQuery(graphql`
    query {
      strapi {
        countries(sort: "name") {
          name
          codename
          IsoAlpha3
          unicodeFlagChar
        }

        products(where: { visible: true }) {
          name
          codename
          enterpriseAvailable
          countries {
            name
            codename
            IsoAlpha3
            unicodeFlagChar
          }
        }
      }
    }
  `).strapi;
  const countriesSorted = countries.sort((c1, c2) => {
    if (c1.codename === 'others') return 1;
    if (c2.codename === 'others') return -1;
    return 0;
  });
  const productsSorted = products
    .sort((p1, p2) => productOrder[p1.codename] - productOrder[p2.codename])
    .filter(p => ['card', 'count', 'match', 'pay'].includes(p.codename))
    .filter(p => (variant === 'enterprise' ? p.enterpriseAvailable : true));
  return (
    <CountriesAllProductsBackground variant={variant}>
      <Container variant={variant}>
        <ImageWrapper>
          <GatsbyImage
            {...(image?.childImageSharp || image?.imageSharp.childImageSharp)}
          />
        </ImageWrapper>
        <Content>
          <SuperHeader
            dangerouslySetInnerHTML={{ __html: title }}
            style={{ marginBottom: '16px' }}
          />
          <H3 weight="normal" style={{ maxWidth: '32em' }}>
            {description}
          </H3>
          <CountriesTableWrapper>
            <OnlyDesktop>
              <DesktopTable
                countries={countriesSorted}
                products={productsSorted}
              />
            </OnlyDesktop>
            <OnlyMobile>
              <MobileTable
                countries={countriesSorted}
                products={productsSorted}
              />
            </OnlyMobile>
          </CountriesTableWrapper>
        </Content>
      </Container>
    </CountriesAllProductsBackground>
  );
}

function DesktopTable({ countries, products }) {
  return (
    <CountriesTable>
      <thead>
        <tr>
          <th aria-label="País" />
          {countries.map(({ codename, IsoAlpha3, unicodeFlagChar }) => (
            <th key={IsoAlpha3}>
              {codename === 'others'
                ? 'Otros'
                : `${unicodeFlagChar} ${IsoAlpha3}`}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {products.map(({ codename, name, countries: productCountries }) => (
          <tr key={codename}>
            <H3 as="td" style={{ fontWeight: 'bold', textAlign: 'left' }}>
              Increase <span className={codename}>{name}</span>
            </H3>
            {countries.map(({ codename: countryCodename }) => (
              <td key={countryCodename} className={codename}>
                {productCountries
                  .map(({ codename }) => codename)
                  .includes(countryCodename) ? (
                  <CountryAvailabilityCircle active />
                ) : (
                  <CountryAvailabilityCircle />
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </CountriesTable>
  );
}

function MobileTable({ countries, products }) {
  return (
    <CountriesTable>
      <thead>
        <tr>
          <th aria-label="País" />
          {products.map(({ codename, name }) => (
            <H3 as="th" key={codename} style={{ width: '6em' }}>
              <strong>
                <span className={codename}>{name}</span>
              </strong>
            </H3>
          ))}
        </tr>
      </thead>
      <tbody>
        {countries.map(({ unicodeFlagChar, codename }) => (
          <tr key={codename}>
            <td
              style={{
                fontSize: codename === 'others' ? '16px' : '26px',
              }}
            >
              {codename === 'others' ? 'Otros' : unicodeFlagChar}
            </td>
            {products.map(product => (
              <td
                className={product.codename}
                key={product.codename}
                style={{ textAlign: 'center' }}
              >
                {product.countries
                  .map(({ codename }) => codename)
                  .includes(codename) ? (
                  <CountryAvailabilityCircle active />
                ) : (
                  <CountryAvailabilityCircle />
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </CountriesTable>
  );
}
