import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ProductsBackgroundBase } from './ProductsBackgroundBase';
import { RoundedSquare } from './shapes/RoundedSquare';
import { MEDIUM } from '../breakpoints';

const BorderedSquare = styled(RoundedSquare).attrs({
  size: '266px',
  rotation: '45deg',
})`
  border: 2px solid ${({ theme }) => theme.colorsNew.increase.accent};
  position: absolute;
  bottom: 0;
  right: -133px;
  @media (min-width: ${MEDIUM}) {
    right: -10%;
    bottom: -40px;
  }
`;

const MediumSquare = styled(RoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.increase.accent,
  size: { sm: '24px', md: '128px' },
})`
  position: absolute;
  top: 64px;
  right: 5%;
  @media (min-width: ${MEDIUM}) {
    right: unset;
    left: -24px;
    top: 100px;
  }
`;

export function ProductsBackgroundEnterprise({ children }) {
  const theme = useTheme();
  return (
    <ProductsBackgroundBase
      baseBackground={theme => theme.colorsNew.gray.black}
      lowerBackgroundColor={theme.colorsNew.increase.dark}
      lowerBackgroundOpacity="0.4"
      upperBackgroundHeight={{ sm: '90%', md: '85%' }}
      upperBackgroundColor={`linear-gradient(180deg,${theme.colorsNew.gray.black} 0%, ${theme.colorsNew.product.dark} 100%)`}
      globalMiscs={<BorderedSquare />}
      upperOnlyMiscs={<MediumSquare />}
    >
      {children}
    </ProductsBackgroundBase>
  );
}
