import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ProductsBackgroundBase } from './ProductsBackgroundBase';
import { RoundedSquare } from './shapes/RoundedSquare';
import { MEDIUM } from '../breakpoints';

const SmallSquare = styled(RoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.increase.dark,
  size: '29px',
})`
  position: absolute;
  left: 5%;
  top: 48px;
  @media (min-width: ${MEDIUM}) {
    left: 15%;
    top: 85px;
  }
`;

const MediumSquare = styled(RoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.increase.dark,
  size: '94px',
})`
  position: absolute;
  display: none;
  @media (min-width: ${MEDIUM}) {
    display: block;
    right: 5%;
    top: -40px;
  }
`;

export function ProductsBackgroundProducts({ children }) {
  const theme = useTheme();
  return (
    <ProductsBackgroundBase
      baseBackground={theme => theme.colorsNew.gray.white}
      lowerBackgroundColor={theme.colorsNew.gray.white}
      upperBackgroundHeight={{ sm: '528px', md: '70%' }}
      upperBackgroundColor={theme.colorsNew.product.dark30}
      lowerOnlyMiscs={<MediumSquare />}
      upperOnlyMiscs={<SmallSquare />}
    >
      {children}
    </ProductsBackgroundBase>
  );
}
