import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ProductsBackgroundBase } from './ProductsBackgroundBase';
import { MEDIUM } from '../breakpoints';
import { RoundedSquare } from './shapes/RoundedSquare';

const DarkBigSquare = styled(RoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.product.dark,
  size: '592px',
})`
  position: absolute;
  mix-blend-mode: multiply;
  opacity: 0.2;
  top: -100px;
  right: -100px;

  @media (min-width: ${MEDIUM}) {
    top: -100px;
    right: -100px;
  }
`;

export function ProductsBackgroundHome({ children }) {
  const theme = useTheme();
  return (
    <ProductsBackgroundBase
      baseBackground={theme => theme.colorsNew.gray.white}
      upperOnlyMiscs={<DarkBigSquare />}
      lowerBackgroundColor={{
        sm: `linear-gradient(180deg, ${theme.colorsNew.product.light} 0%,${theme.colorsNew.product.light} 82%, ${theme.colorsNew.product.dark} 82%)`,
        md: theme.colorsNew.product.dark,
      }}
      lowerBackgroundOpacity="0.2"
      upperBackgroundHeight={{ sm: '400px', md: '70%' }}
      upperBackgroundColor={theme.colorsNew.product.dark}
    >
      {children}
    </ProductsBackgroundBase>
  );
}
