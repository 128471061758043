import React from 'react';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { RoundedSquare } from './shapes/RoundedSquare';
import { BackgroundBase } from './BackgroundBase';

const BigSquare = styled(RoundedSquare).attrs({
  bgColor: theme => theme.colorsNew.product.light,
  size: { sm: '392px', md: '694px' },
})`
  ${({ variant }) => variant === 'enterprise' && `display: none;`}
  position: absolute;
  opacity: 0.5;
  bottom: -120px;
  left: -240px;

  @media (min-width: ${MEDIUM}) {
    bottom: 0px;
    left: calc(10% - 350px);
  }
`;

export function CountriesAllProductsBackground({ children, variant }) {
  return (
    <BackgroundBase
      allowOverflow
      baseBackground={theme =>
        variant === 'enterprise'
          ? theme.colorsNew.gray.black
          : theme.colorsNew.gray.white
      }
      variant={variant}
      renderBackground={_ => (
        <>
          <BigSquare variant={variant} />
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
