import React from 'react';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { BackgroundBase } from './BackgroundBase';

const UpperBackground = styled.div`
  background: ${({ upperBackgroundColor }) =>
    typeof upperBackgroundColor === 'object'
      ? upperBackgroundColor.sm
      : upperBackgroundColor};
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: ${({ upperBackgroundHeight }) => upperBackgroundHeight.sm};

  @media (min-width: ${MEDIUM}) {
    background: ${({ upperBackgroundColor }) =>
      typeof upperBackgroundColor === 'object'
        ? upperBackgroundColor.md
        : upperBackgroundColor};
    height: ${({ upperBackgroundHeight }) => upperBackgroundHeight.md};
  }
`;

const LowerBackground = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  opacity: ${({ lowerBackgroundOpacity }) => lowerBackgroundOpacity || '1'};
  background: ${({ lowerBackgroundColor }) =>
    typeof lowerBackgroundColor === 'object'
      ? lowerBackgroundColor.sm
      : lowerBackgroundColor};
  top: ${({ upperBackgroundHeight }) => upperBackgroundHeight.sm};
  left: 0;
  height: calc(
    100% - ${({ upperBackgroundHeight }) => upperBackgroundHeight.sm}
  );

  @media (min-width: ${MEDIUM}) {
    background: ${({ lowerBackgroundColor }) =>
      typeof lowerBackgroundColor === 'object'
        ? lowerBackgroundColor.md
        : lowerBackgroundColor};
    top: ${({ upperBackgroundHeight }) => upperBackgroundHeight.md};
    height: calc(
      100% - ${({ upperBackgroundHeight }) => upperBackgroundHeight.md}
    );
  }
`;

export function ProductsBackgroundBase({
  children,
  baseBackground,
  upperBackgroundHeight,
  upperBackgroundColor,
  lowerBackgroundColor,
  lowerBackgroundOpacity,
  upperOnlyMiscs,
  lowerOnlyMiscs,
  globalMiscs,
}) {
  return (
    <BackgroundBase
      allowOverflow
      baseBackground={baseBackground}
      renderBackground={_ => (
        <>
          <UpperBackground
            upperBackgroundHeight={upperBackgroundHeight}
            upperBackgroundColor={upperBackgroundColor}
          >
            {upperOnlyMiscs}
          </UpperBackground>
          <LowerBackground
            upperBackgroundHeight={upperBackgroundHeight}
            lowerBackgroundColor={lowerBackgroundColor}
            lowerBackgroundOpacity={lowerBackgroundOpacity}
          >
            {lowerOnlyMiscs}
          </LowerBackground>
          {globalMiscs}
        </>
      )}
    >
      {children}
    </BackgroundBase>
  );
}
