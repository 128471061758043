import styled from 'styled-components';
import { MEDIUM } from '../../breakpoints';

export const CountriesTableWrapper = styled.div`
  box-shadow: 0px 2.14816px 10.7408px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  margin: 40px 0;
  background: ${({ theme }) => theme.colorsNew.gray.white};
  width: 100%;
  padding: 12px;

  @media (min-width: ${MEDIUM}) {
    width: max-content;
    padding: 40px 0;
  }
`;

export const CountriesTable = styled.table`
  color: ${({ theme }) => theme.colorsNew.gray.black};
  width: 100%;
  td,
  th {
    vertical-align: middle;
    padding: 8px;
    text-align: center;
  }

  tr {
    height: 56px;

    :not(:first-child) > th,
    :not(:first-child) > td {
      border-top: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
    }
  }

  th {
    ${({ theme }) => theme.typography.body}
    font-weight: normal;
  }

  @media (min-width: ${MEDIUM}) {
    tr {
      height: 64px;
    }
    tbody > tr {
      position: relative;

      > td {
        position: relative;
        z-index: 1;
      }
    }
    td,
    th {
      padding: 8px 40px;
      text-align: center;
    }
  }
`;

export const CountryAvailabilityCircle = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: 0 auto;
  background: ${({ active }) => (active ? 'currentColor' : 'transparent')};
  border: 1.5px solid
    ${({ active, theme }) =>
      active ? 'currentColor' : theme.colorsNew.gray.grayBold};

  @media (min-width: ${MEDIUM}) {
    margin: 0 auto;
  }
`;
