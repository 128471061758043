import React from 'react';
import { Container as BaseContainer } from '../components/Container';
import styled from 'styled-components';
import { H3 } from '@increasecard/typed-components';
import { Grid } from '../components/Grid';
import { useStaticQuery, graphql } from 'gatsby';
import { ProductCard } from '../components/ProductCard';
import { MEDIUM } from '../breakpoints';
import { ProductsBackground } from '../backgrounds/ProductsBackground';
import { SuperHeader } from '../components/Typography';
import { CTAButton } from '../components/CTAButton';

const productOrder = {
  card: 1,
  match: 2,
  pay: 3,
  count: 4,
};

const Container = styled(BaseContainer)`
  text-align: center;
  padding-top: 80px;
  padding-bottom: 32px;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  @media (min-width: ${MEDIUM}) {
    padding-top: 80px;
    padding-bottom: 32px;
  }
`;

const Title = styled(SuperHeader)`
  margin-bottom: 32px;
`;

const ProductCards = styled(Grid)`
  justify-items: center;
  grid-row-gap: 32px;

  > * {
    max-width: 328px;
  }

  @media (min-width: ${MEDIUM}) {
    justify-items: unset;
  }
`;

const CTA = styled(CTAButton)`
  margin-top: 32px;
  margin-bottom: 32px;
`;

export function ProductsSection({
  title,
  description,
  primaryCTA,
  backgroundVariant,
  variant,
}) {
  const { products } = useStaticQuery(graphql`
    query {
      strapi {
        products(where: { visible: true }) {
          name
          codename
          description
          enterpriseAvailable
        }
      }
    }
  `).strapi;
  const visibleProducts = products
    .filter(({ codename, enterpriseAvailable }) => {
      if (['increasecard', 'increasepay'].includes(codename)) return false;
      return variant === 'enterprise' ? enterpriseAvailable : true;
    })
    .sort((p1, p2) => productOrder[p1.codename] - productOrder[p2.codename]);
  return (
    <ProductsBackground backgroundVariant={backgroundVariant}>
      <Container>
        <Title>{title}</Title>
        <H3
          dangerouslySetInnerHTML={{ __html: description }}
          weight="normal"
          style={{
            maxWidth: '30em',
            margin: primaryCTA ? '8px auto' : '8px auto 32px',
          }}
        />
        {primaryCTA && <CTA type="default-inverted" {...primaryCTA} />}
        <ProductCards columns={visibleProducts.length * 2}>
          {visibleProducts.map(product => (
            <ProductCard
              className="grid-span-lg-2 grid-span-md-8 grid-span-sm-2"
              key={product.codename}
              enterprise={variant === 'enterprise'}
              {...product}
            />
          ))}
        </ProductCards>
      </Container>
    </ProductsBackground>
  );
}
