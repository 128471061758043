import React from 'react';
import { CTAButton } from './CTAButton';
import styled from 'styled-components';
import { MEDIUM } from '../breakpoints';
import { ThemeProvider } from '@increasecard/typed-components';

const Card = styled.div`
  border-radius: 14px;
  background: ${({ theme }) => theme.colorsNew.gray.white};
  color: ${({ theme }) => theme.colorsNew.gray.black};
  height: 224px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 260px;
  box-shadow: ${({ theme }) => theme.shadows.elevateCast};

  /* Double className to raise specificity and avoid !important */
  .productName.productName {
    color: ${({ theme }) => theme.colorsNew.product.regular};
  }

  @media (min-width: ${MEDIUM}) {
    width: auto;
  }
`;
const Title = styled.h2`
  margin: 32px 0 16px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
`;
const Description = styled.p`
  ${({ theme }) => theme.typography.H3}
  padding: 0 8px 32px;
`;
const CTA = styled(CTAButton)`
  border-radius: 0 0 14px 14px;
  height: 50px;
  :hover {
    box-shadow: none;
  }

  @media (min-width: ${MEDIUM}) {
    width: 100%;
  }
`;

export function ProductCard({
  className,
  name,
  codename,
  description,
  enterprise,
}) {
  return (
    <ThemeProvider productName={codename}>
      <Card className={className}>
        <Title>
          <span className="productName">{name}</span>
        </Title>
        <Description>{description}</Description>
        <CTA
          href={`/${enterprise ? 'enterprise' : 'productos'}/${
            codename === 'count' ? 'count#countEmbed' : codename
          }`}
          text="Conoce más"
        />
      </Card>
    </ThemeProvider>
  );
}
