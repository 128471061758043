import React from 'react';
import { ProductsBackgroundHome } from './ProductsBackgroundHome';
import { ProductsBackgroundProducts } from './ProductsBackgroundProducts';
import { ProductsBackgroundEnterprise } from './ProductsBackgroundEnterprise';

export function ProductsBackground({ children, backgroundVariant }) {
  switch (backgroundVariant) {
    case 'enterpriseHome':
      return (
        <ProductsBackgroundEnterprise>{children}</ProductsBackgroundEnterprise>
      );
    case 'productos':
      return (
        <ProductsBackgroundProducts>{children}</ProductsBackgroundProducts>
      );
    case 'increaseHome':
    default:
      return <ProductsBackgroundHome>{children}</ProductsBackgroundHome>;
  }
}
